enum CustomTrackingEvent {
  PACKAGE_LIST_VIEW = 'PACKAGE_LIST_VIEW',
  PRODUCT_EXTRA_VIEW = 'PRODUCT_EXTRA_VIEW',
  CART_CHECKOUT_START = 'CART_CHECKOUT_START',
  CART_CHECKOUT_FINISH = 'CART_CHECKOUT_FINISH',
  INSURANCE_ADDED_POPUP = 'INSURANCE_ADDED_POPUP',
  INSURANCE_ADDED_CHECKOUT = 'INSURANCE_ADDED_CHECKOUT',
  MMB_ADD_NIGHT = 'MMB_ADD_NIGHT',
  MMB_NAME_CHANGE = 'MMB_NAME_CHANGE',
  MMB_REBOOK_COMPLETELY = 'MMB_REBOOK_COMPLETELY',
  MMB_EXTRAS_PURCHASE = 'MMB_EXTRAS_PURCHASE'
}

export default CustomTrackingEvent;
