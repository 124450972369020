import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { IsoDropdown, IsoDropdownItem, useMediaQuery, useTranslation } from '@ibe/components';
import { manageBookingPages, PageUrl } from '../../pages';
import fallback from '../../Translations/generated/agency-menu.en.json';
import Keys from '../../Translations/generated/agency-menu.en.json.keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useMount } from 'react-use';
import useQuery from '../../Util/useQuery';
import { QueryUtils } from '../../Util/QueryUtils';
import { Button } from 'reactstrap';

const AgencyMenu = ({
  displayDivider,
  userName
}: {
  displayDivider?: boolean;
  userName: string | undefined;
}) => {
  const { t } = useTranslation('agency-menu', fallback);
  const [displayAgencyMenu, setDisplayAgencyMenu] = useState<boolean>(false);
  const [userMenuDropdownOpen, setUserMenuDropdownOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const workflowName = QueryUtils.getWorkflowCode(useQuery()) || '';
  const history = useHistory();

  useMount(() => {
    if (location.pathname && !manageBookingPages.includes(location.pathname as PageUrl)) {
      setDisplayAgencyMenu(true);
    }
  });

  return (
    <>
      {displayAgencyMenu && (
        <div className={'langContainer'}>
          <IsoDropdown
            isOpen={userMenuDropdownOpen}
            onDropdownToggle={setUserMenuDropdownOpen}
            right
            title={
              isDesktop ? (
                userName
              ) : (
                <div className="ibe-header__user" id="ibe-header__user-icon">
                  <span className="mr-1 d-none d-sm-inline">{userName}</span>
                  <FontAwesomeIcon icon={faUser} />
                </div>
              )
            }
            tabIndex={null}
          >
            <div className="ibe-header__user--mobile langContainer-dropdownContent d-sm-none">
              {userName}
            </div>
            <Button
              className="langContainer-dropdownContent"
              onClick={() => history.push(`${PageUrl.AGENCY_PROFILE}?wrk=${workflowName}`)}
              role="menuitem"
            >
              {t(Keys.profile)}
            </Button>
            <Button
              className="langContainer-dropdownContent"
              onClick={() => history.push(`${PageUrl.BOOKINGS}?wrk=${workflowName}`)}
              role="menuitem"
            >
              {t(Keys.bookings)}
            </Button>
            <Button
              className="langContainer-dropdownContent"
              onClick={() =>
                history.push(
                  !!workflowName ? `${PageUrl.LOGOUT}?wrk=${workflowName}` : PageUrl.LOGOUT
                )
              }
              role="menuitem"
            >
              {t(Keys.logout)}
            </Button>
          </IsoDropdown>
          {displayDivider && <div className={'divider'} />}
        </div>
      )}
    </>
  );
};

export default AgencyMenu;
