import React from 'react';
import './NavigateUpButton.scss';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ScrollerService, useTranslation } from '@ibe/components';
import useScrollPosition from '../../Hooks/useScrollPositionHook';
import Keys from '../../Translations/generated/navigate-up-button.en.json.keys';
import fallback from '../../Translations/generated/navigate-up-button.en.json';

const NavigateUpButton = (): JSX.Element => {
  const scrollPosition = useScrollPosition();
  const { t } = useTranslation('navigate-up-button', fallback);
  return (
    <Button
      className={`rounded-0 navigate-up-button navigate-up-button-${
        scrollPosition > 0 ? 'show' : 'hide'
      }`}
      onClick={() => ScrollerService.scrollToTop()}
      aria-label={t(Keys.up)}
    >
      <FontAwesomeIcon icon={faChevronUp} />
    </Button>
  );
};

export default NavigateUpButton;
