import React, { useState } from 'react';
import { useExternalSearchParams } from '../../Config/ExternalParamsContext';
import getExternalConfig from '../../Config/externalConfigs';
import useCms from '../../Hooks/useCms';
import { useMount } from 'react-use';
import './Spinner.scss';

export const Spinner = (props: { defaultSpinner?: boolean }): JSX.Element => {
  const externalSearchParams = useExternalSearchParams();
  const cms = useCms();
  const [ajaxLoader, setAjaxLoader] = useState<string>();
  const translationsUrl = externalSearchParams
    ? getExternalConfig(externalSearchParams, {})?.translationsUrl
    : '';
  const defaultAjaxLoader = `${translationsUrl || '.'}/Images/ajax-loader.svg`;
  const cachedItem = sessionStorage.getItem('AJAX-LOADER');

  useMount(async () => {
    if (cachedItem) {
      setAjaxLoader(cachedItem);
    } else if (props.defaultSpinner) {
      setAjaxLoader(defaultAjaxLoader);
    } else {
      cms
        .fetchLoadingImage()
        .then(v => {
          sessionStorage.setItem('AJAX-LOADER', v ? v : defaultAjaxLoader);
          setAjaxLoader(v ? v : defaultAjaxLoader);
        })
        .catch(() => setAjaxLoader(defaultAjaxLoader));
    }
  });

  return <div className="meg_spinner">{ajaxLoader && <img src={ajaxLoader} alt="loader" />}</div>;
};
