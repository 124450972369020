import { ApiComponent, ApiPrice } from '@ibe/api';

class AddExtrasEvent {
  extrasPrice: ApiPrice;

  purchasedExtras: ApiComponent[] | null;

  bookingNumber: string;
}

export default AddExtrasEvent;
