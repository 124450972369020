/* eslint-disable */
import { observer } from 'mobx-react';
import React, { useState } from 'react';
// eslint-disable-next-line
const { slide: Menu } = require('react-burger-menu');
import { Button, Collapse } from 'reactstrap';
import {
  faAngleDown as angleDown,
  faAngleRight as angleRight
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link, useHistory } from 'react-router-dom';
import { MenuItem } from '@ibe/components';

interface Props {
  isVisible: boolean;
  menuItemsMap: Map<string, MenuItem[]>;
  className?: string;
}

const MultiLevelNavigationMenu = observer(function MultiLevelNavigationMenu({
  isVisible,
  className,
  menuItemsMap
}: Props): JSX.Element {
  const [openStates, setOpenStates] = useState(new Array<string>());
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();

  function toggle(e: React.SyntheticEvent, stateId: string): void {
    e.preventDefault();
    const openStatesTmp = Object.assign([], openStates);

    if (openStatesTmp.includes(stateId)) {
      const index = openStatesTmp.indexOf(stateId, 0);
      if (openStatesTmp.indexOf(stateId, 0) > -1) {
        openStatesTmp.splice(index, 1);
      }
    } else {
      openStatesTmp.push(stateId);
    }
    setOpenStates(openStatesTmp);
  }

  function getKeys(): string[] {
    const keys: string[] = [];
    menuItemsMap.forEach((_value, key) => {
      keys.push(key);
    });
    return keys;
  }

  function renderMenuEntry(key: string): JSX.Element {
    const menuItems: MenuItem[] = menuItemsMap.get(key) || [];

    if (!menuItems.length) {
      return <React.Fragment key={key} />;
    }

    if (menuItems.length === 1) {
      return (
        <React.Fragment key={key}>
          <Button
            color="link"
            onClick={(): void => history.push(menuItems[0].link)}
            className="w-100 justify-content-between navigation-button"
          >
            {menuItems[0].label}
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={key}>
        <Button
          color="link"
          onClick={(event): void => toggle(event, key)}
          className="w-100 justify-content-between navigation-button"
        >
          {menuItems[0].label}
          {!openStates.includes(key) ? <Icon icon={angleRight} /> : <Icon icon={angleDown} />}
        </Button>
        <Collapse key={`collapse_${key}`} isOpen={openStates.includes(key)}>
          <ul className={'menu-items'}>
            {menuItems.map((v: MenuItem) => (
              <li key={v.link}>
                {!v.isRoot && (
                  <Link
                    to={v.link}
                    onClick={(): void => setOpen(false)}
                    className="menu-item"
                    key={`navItem_${v.link}`}
                  >
                    {v.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </Collapse>
      </React.Fragment>
    );
  }

  return (
    <>
      {isVisible && (
        <div className={className}>
          <Menu
            onOpen={(): void => setOpen(true)}
            onClose={(): void => setOpen(false)}
            isOpen={isOpen}
            className="iso_multi_level_navigation-class"
            overlayClassName="iso_multi_level_navigation-overlay"
          >
            <div>{getKeys().map(key => renderMenuEntry(key))}</div>
          </Menu>
        </div>
      )}
    </>
  );
});

export default MultiLevelNavigationMenu;
