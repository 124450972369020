import AdditionalNightEvent from '../Events/Payload/AdditionalNightEvent';
import { BookingEvent, Channel } from '@ibe/services';
import NameChangedEvent from '../Events/Payload/NameChangedEvent';
import AddExtraEvent from '../Events/Payload/AddExtraEvent';

export const additionalNightEvent = new Channel<AdditionalNightEvent>(
  AdditionalNightEvent,
  'add-night-mmb'
);

export const nameChangedEvent = new Channel<NameChangedEvent>(NameChangedEvent, 'name-change-mmb');

export const rebookCompletelyEvent = new Channel<BookingEvent>(
  BookingEvent,
  'rebook-completely-mmb'
);

export const addExtraEvent = new Channel<AddExtraEvent>(AddExtraEvent, 'extras-purchase-mmb');
