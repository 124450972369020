import { ConfigModel } from '@ibe/services';

class MegConfigModel extends ConfigModel {
  hideSpecialRequests?: boolean;

  disablePackageNavigation?: boolean;

  hideMoveTicketOnHotelList?: boolean;

  disableBookingOverviewAddressChange?: boolean;

  paymentStyleUrl?: string;

  hotelListResultsQuantity?: number;

  hideOccupancyInRoomsItem?: boolean;

  cachePackageComponentsRequests?: boolean;

  hidePaymentInformation?: boolean;

  showTravelPlan?: boolean;

  multipleTimeSlots?: boolean;

  showWalletLinks?: boolean;

  showCurrencySelection?: boolean;

  conference?: {
    entryCode?: string;
    taxNumberMaxLength?: number;
  };

  enableOptionalRelatedServices?: boolean;

  payConnectWaitTime?: number;
}

export default MegConfigModel;
