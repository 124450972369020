/* eslint import/no-cycle: "off" */
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { pageTitle } from '../static';
import Header from './Header/Header';
import NavigateUpButton from './../Components/NavigateUpButton/NavigateUpButton';
import Headroom from 'react-headroom';
import { BreadcrumbProps, MEDIAQUERY_DEFAULTS, useConfig, useMediaQuery } from '@ibe/components';
import { ApiIncludedService, ApiPackageModel } from '@ibe/api';
import { LoggerFactory } from '@ibe/services';

export interface LocationSnapshot {
  pathname: string;
  search: string;
}
interface Props {
  children: React.ReactNode;
  title?: string;
  stickyHeader?: boolean;
  breadcrumbProps?: BreadcrumbProps;
  onGoToCheckout?: () => Promise<void>;
  editBookingItemExtension?: (itemIDs: Array<string>) => void;
  className?: string;
  packageIncludedServices?: Array<ApiIncludedService>;
  packageModel?: ApiPackageModel;
  displayHeader?: boolean;
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;

const Center = styled.div`
  margin: auto;
`;

const logger = LoggerFactory.get('BlankPageLayout');

const BlankPageLayout = observer(function BlankPageLayout(props: Props) {
  const {
    children,
    stickyHeader,
    breadcrumbProps,
    onGoToCheckout,
    editBookingItemExtension,
    className,
    packageIncludedServices,
    packageModel,
    displayHeader = true
  } = props;
  const config = useConfig();
  const { stickyElementsOffset } = config;
  const isTablet = useMediaQuery({
    type: 'min',
    query: stickyElementsOffset?.tablet.breakpoint.toString() || MEDIAQUERY_DEFAULTS.md
  });
  const isDesktop = useMediaQuery({
    type: 'min',
    query: stickyElementsOffset?.desktop.breakpoint.toString() || MEDIAQUERY_DEFAULTS.lg
  });

  const getOffset = useMemo((): number => {
    let newOffset = 0;
    if (!!stickyElementsOffset && stickyHeader) {
      if (isTablet && !isDesktop) {
        newOffset = stickyElementsOffset.tablet.offset;
      } else if (isDesktop) {
        newOffset = stickyElementsOffset.desktop.offset;
      } else {
        newOffset = stickyElementsOffset.mobile.offset;
      }
    }
    return newOffset;
  }, [isDesktop, isTablet, stickyElementsOffset, stickyHeader]);

  const title = props.title ? `${props.title} | ${pageTitle}` : `${pageTitle}`;

  return (
    <Layout className={`blank-page-layout ${className}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      {displayHeader && (
        <div
          className={`mb-0 mb-md-3${stickyHeader ? ' headroom--sticky' : ''}`}
          style={{ top: `${getOffset}px` }}
        >
          <Headroom disableInlineStyles pinStart={0} disable={!stickyHeader} className="mb-3">
            <div className="iso__header-container">
              <Header
                breadcrumbProps={breadcrumbProps}
                onGoToCheckout={onGoToCheckout}
                editBookingItemExtension={editBookingItemExtension}
                packageIncludedServices={packageIncludedServices}
                packageModel={packageModel}
              />
            </div>
          </Headroom>
        </div>
      )}
      <h1 className={'page-title'} role={'main'}>
        {title}
      </h1>
      <Center className="backgroundContainer container-fluid">{children}</Center>
      <NavigateUpButton />
    </Layout>
  );
});

export default BlankPageLayout;
